// IE11対応
import 'core-js/stable' // import '@babel/polyfill' のかわり
import 'regenerator-runtime/runtime' // import '@babel/polyfill' のかわり
import 'dom4' // DOM4 ployfill
import 'whatwg-fetch' // fetch() polyfill
import Stickyfill from 'stickyfilljs' // display: sticky polyfill
Stickyfill.add(document.querySelector('.js-header'))

// scss
import '../scss/style.scss'

// 共通
import { airbrakeNotify } from './airbrake'
import { smoothScroll } from './header/smooth-scroll'
import { spHeaderNav } from './header/sp-header-nav'

// 共通
airbrakeNotify()
smoothScroll()

// SP実行
const spMediaQuery = window.matchMedia('(max-width: 767px)')

if (spMediaQuery.matches) {
  spHeaderNav()
}

// 切り替え
spMediaQuery.addListener(mediaQueryListMatches)

function mediaQueryListMatches(event) {
  if (event.matches) {
    spHeaderNav()
  }
}
